import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Badge, Button } from 'react-bootstrap';
import { DataManager } from '../../core/data-manager';
import { ApiManager } from '../../core/api-manager';
import { LanguageProvider } from '../../core/language-provider';



export default function DataGridLastTdHook({ item, props, entity = null}) {

    const navigate = useNavigate()

    useEffect(() => {
        
    }, []);


    function goToCourseDetails(item) {
        navigate('/page/CourseDetails?id=' + item.id)
    }
    function goToUserCourse(item) {
        navigate('/page/UserCourse?id=' + item.id)
    }



    if (entity !== null) {
        return (
            <>
                {(() => {
                    switch(entity) {
                        case 'sample': return (
                            <span>Test</span>
                        );
                        case 'course': return (
                            <>
                                <Button onClick={()=>goToCourseDetails(item)} size={'sm'} variant='outline-secondary'>Visualizza episodi</Button>
                                <Button onClick={()=>goToUserCourse(item)}  size={'sm'} variant='outline-primary' className='ms-1'>Gestisci utenti</Button>
                            </>
                        );
                        default: return (
                            <></>
                        );
                    }
                })()}
            </>
        )
    }
    else {
        return (
            <></>
        )
    }
};