import logo from '../assets/logo_white.svg';

export const settings = {
    "title": "Backoffice e-Library",
    "apiUrl": "https://api-elibrary.fedespedi.it/api",
    "ckdPath": "https://api-elibrary.fedespedi.it/",
    "tinyKey": "u2y9r6cw0e1erg33drzg4fs0swwi1zrs85bcin3ynrsxlwz2",
    "config": {
        "limit": 100,
        "sortingField": 'id',
        "sortingOrder": 'desc'
    },
    "theme": {
        "siderbarIconColor": 'rgba(233,236,239,.5)'
    },
    "logo": logo
}